<template>
<div>
<a href="#content" class="skip">Skip to content</a>
<div id="portal-wrapper" :style="globalBackgroundWhite ? 'backgroundColor:white!important' : ''">
    <div id="portal-header">
        <div class="ml-5 page-title">
            <span>{{ title }}</span>
        </div>
        <current-user />
    </div>
    
    <provider-portal-sidebar id="portal-sidebar"/>
    
    <div id="content" :style="globalBackgroundWhite ? 'backgroundColor:white' : ''" class="p-3 pt-4">
        <router-view></router-view>
    </div>
    <div id="footer"></div>
    <feedback-button/>
</div>
</div>
</template>

<script>
import ProviderPortalSidebar from "@/components/providerPortal/ProviderPortalSidebar.vue"
import CurrentUser from "@/components/CurrentUser.vue"
import FeedbackButton from '@/components/FeedbackButton.vue'

export default {
  name: 'ProviderPortal',
  components: {
      ProviderPortalSidebar,
      CurrentUser,
      FeedbackButton,
  },
  methods: {
  },
  computed: {
      title(){
          return this.$route?.meta?.header_text || "Provider Portal";
      },
      activeTab(){
          return this.$store.state.providerPortal.activeNavTab;
      },
      globalBackgroundWhite(){
          return this.$store.state.providerPortal.globalBackgroundWhite;
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#portal-wrapper {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: min-content auto;
    grid-template-areas:
    "sidebar header"
    "sidebar content";
    /* "footer footer"; */
    background-color: #F8FAFD !important;
}
#portal-header {
    align-items: center;
    position:sticky;
    top:0;
    height:70px;
    grid-area: header;
    display: grid;
    grid-template-columns: 25em auto;
    grid-template-areas:
    "content current-user";
    background-color: #F8FAFD !important;
    z-index:50;
}
#portal-sidebar {
    position:sticky;
    top:0;
    height:100vh;
    grid-area: sidebar;
    z-index:50;
}
#portal-logo {
    width: 4em;
    grid-area: logo;
}
#portal-logo img {
    width: 90%;
}
#current-user {
    grid-area: current-user;
    justify-self: end;
    align-self: center;
}
#app {
    grid-area: content;
}
#content {
    width:100%;
    background-color: #F8FAFD;
    min-height: 90vh;
}
.page-title {
    font-weight:300;
    font-size:26px;
    color: var(--dark);
}
</style>